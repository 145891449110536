
export const serverAppConfig = {
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "6537c6f279a153613ecc8ed3",
      "name": "Standardleverans 1-2 dagar",
      "label": "Leveranstid 1-2 dagar",
      "buyable": true
    },
    {
      "id": "6583f92d79a153613ecc8ff3",
      "name": "Standardleverans 2-4 dagar - Ej i lager",
      "label": "Ej i lager - Normal leveranstid 2-4 dagar",
      "buyable": true
    }
  ],
  "language": "sv",
  "paths": {
    "brandsUrlPath": "/brands",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/tags",
    "productsUrlPath": "/products",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Tillverkare",
      "path": "/brands"
    },
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1047/american_popcorn_liggande.png",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "45vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 499,
  "shippingProviderConfig": {
    "provider": "DEFAULT",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 499,
      "deliveryFee": 139
    }
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCountry": "SE",
        "purchaseCurrency": "SEK",
        "phoneMandatory": true,
        "colorButton": null,
        "termsUrl": null,
        "cancellationTermsUrl": null
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "14vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": []
};
export const serverTheme = {
  "colors": {
    "background": {
      "surface": "#ffffff"
    }
  }
}; 
